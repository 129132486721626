import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from '../components/layout';
import Text from '../components/page/text';

export const query = graphql`
  {
    hope {
      allPageTexts(where: { page: Resources }, orderBy: "order") {
        heading
        text
      }
      allResourceTypes(orderBy: "order") {
        title
        resources(orderBy: "order") {
          title
          link
          file {
            filename
            publicUrl
            originalFilename
          }
        }
      }
    }
  }
`;

const Resources = ({ data }) => (
  <Layout>
    <Container style={{ padding: '0 15%' }}>
      <Row>
        <Col>
          <h1 style={{ marginLeft: '-15%' }}>Resources</h1>
        </Col>
      </Row>
      {data.hope.allPageTexts.map(text => (
        <Text key={text.heading} heading={text.heading} text={text.text} />
      ))}
      <Row>
        <Col>
          {data.hope.allResourceTypes.map(type => {
            return (
              <div key={type.title}>
                <h2>{type.title}</h2>
                <ul>
                  {type.resources.map(resource => (
                    <li key={resource.title}>
                      {resource.file ? (
                        <a href={resource.file.publicUrl} download={resource.file.originalFilename}>
                          {resource.title}
                        </a>
                      ) : (
                        <a href={resource.link} target="_blank" rel="noreferrer">
                          {resource.title}
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
                <hr />
              </div>
            );
          })}
        </Col>
      </Row>
    </Container>
  </Layout>
);

Resources.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Resources;
